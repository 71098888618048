.share{
    margin: 0px 3px;
}

.whatsapp{
    background-color: rgb(19, 99, 9);;
    color: white;
}

.twitter{
    background-color: rgb(29, 155, 240);;
    color: white;
}

.facebook{
    background-color: white;
    color: #1877f2;
}

.linkedin{
    background-color: #0A66C2;
    color: white;
}

.mail-alert{
    background-color: orange;
    color: white;
    display: inline!important;
}
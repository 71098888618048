.gender{
    font-size: small;
    color: #333333;
}

.gender::before{
content: "(";
}
    
.gender::after{
content: ")";
}
    
#subject_history{
    border: black solid 1px;
    border-radius: 7px;
    background-color: aquamarine;
    padding: 10px;
    margin: 5px 0px;
}

#subject_history ul{
    list-style-type: none;
    overflow: auto;
    width: 80%;
}

#subject_history li{
    display: inline;
    float: left;
    padding: 0px 5px;
}

#subject_history .nav {
    width: 10%;
    background-color: black;
    color: white;
}

#subject_history .back{
    float: left;
}

#subject_history .forward{
    float: right;
}

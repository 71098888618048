.inspector {
  font: 500 1rem Roboto, helvetica, sans-serif;
  color: #212121; /* Grey 900 */
  cursor: default;
  margin-top: 2px;
}

.inspector td, th {
  padding: 2px;
}

.inspector input {
  font: 300 1rem Roboto, helvetica, sans-serif;
  border: 0;
  outline: none;
  border-bottom: 1px solid #212121; /* Grey 900 */
  padding: 2px;
}

.inspector input:disabled {
  background-color: #EEEEEE; /* Grey 400 */
  color: #616161; /* Grey 700 */
  border-bottom: 1px solid #BDBDBD; /* Grey 900 */
}
/*Disable Wortree specific nodes click events*/
text[wordtree="0"] {
     pointer-events: none;
}

text[wordtree="1"] {
     cursor: pointer;
}

[id^=google-visualization-errors]{
     display: none;
}

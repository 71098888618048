div.show-image {
    position: relative;
    float:left;
  }

div.show-image #plus-menu {
    position:absolute;
    display:block;
    top:1px;
    right:1px;
    cursor: pointer;
    margin: 10px;
    color: aliceblue;
}

div.show-image #plus-menu:hover {
    color: red;
}

.tooltiptext{
    display: none;
}

div.show-image #plus-menu:hover .tooltiptext{
    display: inline-block;
}
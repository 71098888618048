.personnage{
font-weight: bold;
}

.subject-introduction{
  background-color: whitesmoke;
  margin: 10px 0px;
  padding: 5px 10px 0px 10px;
  background: #FFFFEA;
}

.subject-introduction p{
  padding: 0px;
  margin: 0px;
}

.light-italic,
.verb-statement{
color:black;
font-style: italic;
}

.sujet_container{
padding: 2px;
}

.verb_title{
margin: 10px 0px 2px 0px;
padding: 1px 3px;
text-align: left;
border-bottom: 1px solid #f1f1f1;
color: #919191;
}

.button {
border: none;
text-decoration: underline;
text-underline-position: auto;
cursor: pointer;
font-size: 16px;
background: transparent;
margin:0;
padding: 0;
color: green;
}

.bible-text {
    color: darkgreen;
    font-family: small-caption;
}

.bible-button {
    background-color: transparent;
    padding: 0;
    margin: 0;
}

b {
  margin-right: 5px;/* Missing space after subject name from introduction*/
}

div[data-tag="introduction"]{
  padding:10px;
  border-radius: 7px;
}
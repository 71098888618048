.bible-component{
    cursor: pointer;
}

.bible-component .bible-text {
    color: #035992;
    font-family: small-caption;
    margin-left: 5px;
}

.bible-component .bible-text-content {
    display: block;
    background-color:#f3f0f0;
    /*background-color: white;
    border-radius: 7px;
    border:blue 1px solid;*/
    padding: 0px 5px;
}

.inset{
    border:1px white solid;
}

.bible-component .Nv2, 
.bible-component .Nv2-a-supprimer {
    vertical-align:super;
    color: #035992;
    font-size: smaller;
}

.bible-component .bible-title-ref {
    color: #035992; 
    text-align: center;
    width: 100%;
    display: inline-block;
}
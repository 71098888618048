.input-form .form-error {
    width:100%;
    text-align:right;
}
/*
.input-form label{
    background-color: chartreuse;
}*/

.input-form .col-form-label{
    font-weight: bold;
    font-size: larger;
    color:#025d9a;
}

.form-error{
    color: brown;
    font-style: italic;
}